<template>
  
    <video poster="poster.jpg" autoplay playsinline muted loop>
      
      <source src="@/assets/bg.mp4" type="video/mp4">
    </video>
 
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>

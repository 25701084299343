<template>
<div id="background"></div>
<div id="container">
  
  <video id="video" poster="@/assets/bgimage.jpg" @click = "play" playsinline controls >
      
      <source src="@/assets/bg.mp4" type="video/mp4">
    </video>
    <p>Life sized video game experience <br /><span>Coming Soon!</span></p>
    <a id="twitter" href="https://twitter.com/BrickCityHoops">@BrickCityHoops</a>
    </div>
    
  
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  methods:{
    play(){
   
      var video =document.getElementById ("video")
      video.muted = false;
    }
  }
}
</script>

<style>
@font-face {
  font-family: "Groches";
  src: local("Groches"),
   url(./assets/GROCHES.ttf) format("truetype");
}
body,html{background:#000;height:100%; width:100%;

padding:0;
margin:0;
overflow-x:hidden;
font-family:"Groches", sans-serif}
#container{
  position: absolute;
  width:70%;
  left:50%;
  transform: translateX(-50%);
  text-align: center;
}
p{
 
  margin:0 auto;
  
  line-height:2.5vw;
  color:#fff;
  text-align:center; 
  vertical-align: text-top;
  font-size:3vw;
  padding:0;
  margin-top:15px;
  margin-bottom:50px;
 
  }

  p span{
    color:rgb(249, 220, 81);
  }
#background{
   position:absolute;
   width:100%;
   height:100%;
   background:url(./assets/brick.jpg) repeat;
  background-size:30%;
  opacity:0.15;
  object-fit: cover;
  position:fixed
}


video {
    width:100%;;
    border-radius:20px;
    /* position: fixed; */
    left:0;
    z-index: 0;
    margin-top:50px;
}
#twitter{

  color:white;
  font-size:1.5rem;
  text-decoration: underline;
 
  text-transform:uppercase;
 
  text-align:center;
  margin:0 auto;
  

}

#twitter:hover{
  font-style:italic;
}

@media only screen and (max-width: 600px) {
 #container{
   width:100%;
   top:50%;
   transform:translateY(-50%);
   left:0;
 }

 p{font-size:7.5vw; line-height:8vw;}
  #background{background-size:100%;}
  #twitter{font-size:4vw;}
}
</style>
